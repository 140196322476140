<template>
  <div>
    <v-overlay :z-index="100" :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="grey darken-2"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div class="text-h5">Payments</div>

    <!-- START BUTTONS -->
    <div class="text-right">
      <!-- Disabled for now, need to add a bill selector to the form -->
      <v-btn
          disabled
          color="primary"
          class="mt-2"
          @click="addPayment()"
      >
        Add Payment
        <v-icon class="pl-3" dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
    <!-- END BUTTONS -->

    <!-- PAYMENTS TABLE START -->
    <v-data-table
        class="mt-5"
        :headers="tableHeaders"
        :items="filteredData"
        :footer-props="{'items-per-page-options': pagingOptions}"
        :items-per-page="25"
        sort-by="date"
        :sort-desc="true"
        item-key="id"
    >
      <template v-slot:top>
        <v-expansion-panels
            v-model="panels"
            multiple
            flat
            class="mb-3"
        >
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Summary</v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Summary -->
              <v-row class="text-right">
                <v-col cols="6" md="10">
                  <!-- -->
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Total</div></v-card-title>
                    <v-card-subtitle>{{ formattedTotalAmount }}</v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Filtering</v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Filtering -->
              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                      :items="options"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.periodFrom"
                      label="From"
                      placeholder="Select month"
                      clearable
                  />
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="toOptions"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.periodTo"
                      label="To"
                      placeholder="Select month"
                      clearable
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="filtering.search"
                      label="Search by description"
                  >

                  </v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editPayment(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
            small
            @click="deletePayment(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>
    <!-- PAYMENTS TABLE END -->

    <!-- START ADD/EDIT CARD -->
    <v-dialog
        v-model="editMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Payment Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="9"
              >
                <v-text-field
                    label="Description *"
                    required
                    v-model="editData.description"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    prepend-inner-icon="mdi-cash"
                    label="Amount *"
                    required
                    v-model="editData.amount"
                    type="number"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-menu
                    v-model="paymentDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        label="Date*"
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="editData.payment_date"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="editData.payment_date"
                      no-title
                      @input="paymentDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                    :items="accounts"
                    item-value="id"
                    item-text="title"
                    v-model="editData.account_id"
                    label="Account"
                    placeholder="Select account"
                />
              </v-col>

            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="editMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="savePayment()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END ADD/EDIT CARD -->
  </div>
</template>

<script>
import {formatAsCurrency} from "../../services/utils";
export default {
  name: "Payments",

  data () {
    return {
      panels: [],
      pagingOptions: [10,25,50,100,-1],
      paymentDateMenu: false,
      filtering: {
        periodFrom: null,
        periodTo: null,
        search: '',
      },
      tableHeaders: [
        {
          value: 'payment_date',
          text: 'Date',
          sortField: 'date'
        }, {
          value: 'description',
          text: 'Description',
        }, {
          value: 'account_name',
          text: 'Account',
        }, {
          value: 'amount',
          text: 'Amount',
          align: 'end'
        }, { text: 'Actions', value: 'actions', sortable: false },
      ],
      mobileWidth: 767,
      editData: {
        id: 0,
        date: '',
        description: '',
        amount: '',
        bill_id: null,
        account_id: null,
      },
      editMode: false
    }
  },

  mounted() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        this.panels = [];
        break;
      case 'md':
      case 'lg':
      case 'xl':
        this.panels = [0,1];
        break;
    }

    this.$store.dispatch('fetchPaymentsPeriods').then(() => {
      if (this.filtering.periodFrom == null && this.filtering.periodTo == null && (this.options.length > 0)) {
        this.filtering.periodFrom = this.options[0].id;
      }

      this.$store.dispatch('fetchAccounts').then(() => {
        this.$store.dispatch('fetchPayments');
      });
    });
  },

  computed: {
    accounts() {
      return this.$store.state.accounts.accounts;
    },

    options() {
      return this.$store.state.payments.periodsOptions;
    },

    toOptions() {
      return this.options.filter((o) => (
          this.filtering.periodFrom == null
          || o.id >= parseInt(this.filtering.periodFrom)
      ));
    },

    filteredData() {
      return this.paymentData.filter(
          (r) => (this.filtering.periodFrom == null || this.filtering.periodFrom <= r.timestamp)
      ).filter((r) => (this.filtering.periodTo == null || this.filtering.periodTo > r.timestamp)
      ).filter((r) => {
        return r.description.toLowerCase().includes(this.filtering.search.toLowerCase());
      });
    },

    paymentData() {
      return this.$store.state.payments.payments.data;
    },

    loading() {
      return this.$store.state.payments.loading;
    },

    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },

    totalAmount() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.amount_raw);
      return Math.round(total * 100) / 100;
    },

    formattedTotalAmount() {
      return formatAsCurrency(this.totalAmount);
    }
  },

  methods: {
    addPayment() {
      var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let now = [year, month, day].join('-');

      this.editData = {
        id: 0,
        date: now,
        description: '',
        amount: '',
        account: this.accounts[0].id
      }
      this.editMode = true;
    },

    savePayment() {
      if (this.editData.id < 1) {
        this.$store.dispatch('addPayment', this.editData);
      } else {
        this.$store.dispatch('updatePayment', this.editData);
      }
      this.editMode = false;
    },

    editPayment(payment) {
      this.editData.id = payment.id;
      this.editData.description = payment.description;
      this.editData.amount = payment.amount_raw;
      this.editData.payment_date = payment.payment_date;
      this.editData.bill_id = payment.bill_id;
      this.editData.account_id = this.accounts.find((a) => a.id == payment.account_id).id;

      this.editMode = true;
    },

    deletePayment(id) {
      this.$store.dispatch('deletePayment', id);
    },

  }
}
</script>

<style scoped>

</style>